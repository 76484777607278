<template>
  <div>
    <h1 class="text-2xl font-bold text-center mb-6">Статистика</h1>

    <div class="flex flex-col space-y-4 max-w-2xl w-full">
      <!-- Карточка для количества блюд -->
      <div class="bg-white shadow-lg rounded-lg p-4">
        <h2 class="text-xl font-semibold mb-2 text-gray-800 text-center">Количество блюд за 30 дней</h2>
        <div v-if="showDishChart">
          <apexchart type="line" :options="dishChartOptions" :series="dishSeries" class="w-full h-64"></apexchart>
        </div>
        <p class="text-right text-gray-800 mt-2">ИТОГО: {{ totalDishes }} блюд</p>
      </div>

      <!-- Карточка для калорий -->
      <div class="bg-white shadow-lg rounded-lg p-4">
        <h2 class="text-xl font-semibold mb-2 text-gray-800 text-center">Калории за 30 дней</h2>
        <div v-if="showCalorieChart">
          <apexchart type="line" :options="calorieChartOptions" :series="calorieSeries" class="w-full h-64"></apexchart>
        </div>
        <p class="text-right text-gray-800 mt-2">ИТОГО: {{ totalCalories }} ккал</p>
      </div>

      <!-- Карточка для белков, жиров и углеводов -->
      <div class="bg-white shadow-lg rounded-lg p-4">
        <h2 class="text-xl font-semibold mb-2 text-gray-800 text-center">БЖУ за 30 дней</h2>
        <div v-if="showNutrientChart">
          <apexchart type="area" :options="nutrientChartOptions" :series="nutrientSeries" class="w-full h-64"></apexchart>
        </div>
        <p class="text-right text-gray-800 mt-2">
          ИТОГО:
        </p>
        <p class="text-right text-gray-800 mt-2">
          Белки: {{ totalProtein }} г, Жиры: {{ totalFat }} г, Углеводы: {{ totalCarbohydrates }} г
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueApexCharts from 'vue3-apexcharts';

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      user: null,
      dishCounts: [],
      calorieCounts: [],
      nutrientCounts: [],
      isTelegram: false,
      showDishChart: false,
      showCalorieChart: false,
      showNutrientChart: false,
      totalDishes: 0,
      totalCalories: 0,
      totalProtein: 0,
      totalFat: 0,
      totalCarbohydrates: 0,
      dishChartOptions: {
        chart: {
          id: 'dish-count-chart',
          toolbar: {
            show: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800
          },
          zoom: {
            enabled: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: 2
        },
        xaxis: {
          categories: [],
          tickAmount: 5,
          labels: {
            style: {
              colors: '#4A5568'
            }
          }
        },
        yaxis: {
          title: {
            text: 'Количество блюд',
            style: {
              color: '#4A5568'
            }
          },
          labels: {
            style: {
              colors: '#4A5568'
            }
          }
        },
        tooltip: {
          enabled: true,
          x: {
            format: 'dd/MM'
          },
          y: {
            formatter: function(value) {
              return `${value} блюд`;
            }
          }
        },
        grid: {
          padding: {
            top: 0,
            bottom: 0
          }
        }
      },
      dishSeries: [
        {
          name: 'Блюда',
          data: []
        }
      ],
      calorieChartOptions: {
        chart: {
          id: 'calorie-count-chart',
          toolbar: {
            show: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800
          },
          zoom: {
            enabled: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: 2,
          colors: ['#48BB78'] // Зеленая линия для калорий
        },
        xaxis: {
          categories: [],
          tickAmount: 5,
          labels: {
            style: {
              colors: '#4A5568'
            }
          }
        },
        yaxis: {
          title: {
            text: 'Калории',
            style: {
              color: '#4A5568'
            }
          },
          labels: {
            style: {
              colors: '#4A5568'
            }
          }
        },
        tooltip: {
          enabled: true,
          x: {
            format: 'dd/MM'
          },
          y: {
            formatter: function(value) {
              return `${value} ккал`;
            }
          }
        },
        grid: {
          padding: {
            top: 0,
            bottom: 0
          }
        }
      },
      calorieSeries: [
        {
          name: 'Калории',
          data: []
        }
      ],
      nutrientChartOptions: {
        chart: {
          id: 'nutrient-count-chart',
          stacked: true,
          toolbar: {
            show: false
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800
          }
        },
        dataLabels: {
          enabled: false  // Отключаем метки данных
        },
        stroke: {
          curve: 'smooth',  // Плавные линии
          width: 2
        },
        xaxis: {
          categories: [],
          tickAmount: 5,
          labels: {
            style: {
              colors: '#4A5568'
            }
          }
        },
        yaxis: {
          title: {
            text: 'Граммы',
            style: {
              color: '#4A5568'
            }
          },
          labels: {
            style: {
              colors: '#4A5568'
            }
          }
        },
        tooltip: {
          enabled: true,
          x: {
            format: 'dd/MM'
          },
          y: {
            formatter: function(value) {
              return `${value} г`;
            }
          }
        },
        grid: {
          padding: {
            top: 0,
            bottom: 0
          }
        },
        colors: ['#3182CE', '#fdb300', '#E53E3E'] // Цвета для белков, жиров и углеводов
      },
      nutrientSeries: [
        {
          name: 'Белки',
          data: []
        },
        {
          name: 'Жиры',
          data: []
        },
        {
          name: 'Углеводы',
          data: []
        }
      ]
    };
  },
  methods: {
    checkIfTelegram() {
      if (window.Telegram && window.Telegram.WebApp) {
        this.isTelegram = true;
        this.fetchUserData();
      } else {
        this.isTelegram = false;
      }
    },
    async loadTelegramWebAppScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-web-app.js";
        script.onload = () => {
          this.checkIfTelegram();
          resolve();
        };
        script.onerror = () => {
          this.isTelegram = false;
          reject();
        };
        document.head.appendChild(script);
      });
    },
    async fetchUserData() {
      const initData = window.Telegram.WebApp.initDataUnsafe || null;
      if (initData && initData.user) {
        this.user = initData.user;
        await this.fetchMonthlyStats();
        await this.fetchMonthlyCalories();
        await this.fetchMonthlyNutrients();
      }
    },
    async fetchMonthlyStats() {
      if (!this.user) return;

      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/api/monthly_stats/${this.user.id}?_=${timestamp}`, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        this.dishCounts = response.data;

        if (this.dishCounts.length > 0) {
          this.showDishChart = true;
          this.renderDishChart();
        } else {
          this.showDishChart = false;
        }
      } catch (error) {
        console.error('Ошибка при загрузке месячной статистики:', error);
      }
    },
    renderDishChart() {
      const categories = this.dishCounts.map(item => new Date(item.day).getDate());
      const data = this.dishCounts.map(item => parseInt(item.total_dishes));

      this.totalDishes = data.reduce((sum, value) => sum + value, 0);
      this.dishChartOptions.xaxis.categories = categories;
      this.dishSeries[0].data = data;
    },
    async fetchMonthlyCalories() {
      if (!this.user) return;

      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/api/monthly_calories/${this.user.id}?_=${timestamp}`, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        this.calorieCounts = response.data;

        if (this.calorieCounts.length > 0) {
          this.showCalorieChart = true;
          this.renderCalorieChart();
        } else {
          this.showCalorieChart = false;
        }
      } catch (error) {
        console.error('Ошибка при загрузке месячной статистики калорий:', error);
      }
    },
    renderCalorieChart() {
      const categories = this.calorieCounts.map(item => new Date(item.day).getDate());
      const data = this.calorieCounts.map(item => parseInt(item.total_calories));

      this.totalCalories = data.reduce((sum, value) => sum + value, 0);
      this.calorieChartOptions.xaxis.categories = categories;
      this.calorieSeries[0].data = data;
    },
    async fetchMonthlyNutrients() {
      if (!this.user) return;

      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/api/monthly_nutrients/${this.user.id}?_=${timestamp}`,{
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        this.nutrientCounts = response.data;

        if (this.nutrientCounts.length > 0) {
          this.showNutrientChart = true;
          this.renderNutrientChart();
        } else {
          this.showNutrientChart = false;
        }
      } catch (error) {
        console.error('Ошибка при загрузке месячной статистики БЖУ:', error);
      }
    },
    renderNutrientChart() {
      const categories = this.nutrientCounts.map(item => new Date(item.day).getDate());
      const proteinData = this.nutrientCounts.map(item => parseInt(item.total_protein));
      const fatData = this.nutrientCounts.map(item => parseInt(item.total_fat));
      const carbohydrateData = this.nutrientCounts.map(item => parseInt(item.total_carbohydrates));

      this.totalProtein = proteinData.reduce((sum, value) => sum + value, 0);
      this.totalFat = fatData.reduce((sum, value) => sum + value, 0);
      this.totalCarbohydrates = carbohydrateData.reduce((sum, value) => sum + value, 0);

      this.nutrientChartOptions.xaxis.categories = categories;
      this.nutrientSeries[0].data = proteinData;
      this.nutrientSeries[1].data = fatData;
      this.nutrientSeries[2].data = carbohydrateData;
    }
  },
  async mounted() {
    if (window.Telegram && window.Telegram.WebApp) {
      this.checkIfTelegram();
    } else {
      await this.loadTelegramWebAppScript();
    }
  }
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
}

.bg-gray-100 {
  background-color: #F7FAFC;
}

.text-gray-800 {
  color: #2D3748;
}

.bg-white {
  background-color: #FFFFFF;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>
