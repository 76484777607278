<template>
  <div class="bg-dark-green p-4 rounded-lg shadow-md flex flex-col items-center relative">
    <h3 class="text-dark text-lg font-bold mb-4">Распознований</h3>
    <div class="relative w-24 h-24 mx-auto mb-4">
      <svg class="absolute w-full h-full text-gray-200" viewBox="0 0 36 36">
        <path
            class="stroke-current"
            stroke-width="2"
            fill="none"
            d="M18 2.084a15.916 15.916 0 1 1 0 31.832 15.916 15.916 0 1 1 0-31.832"
        ></path>
      </svg>
      <svg class="absolute w-full h-full text-gray-600" viewBox="0 0 36 36">
        <path
            class="stroke-current"
            :stroke-dasharray="`${Math.min((remainingRecognitions / 100) * 100, 100)}, 100`"
            stroke-width="4"
            fill="none"
            d="M18 2.084a15.916 15.916 0 1 1 0 31.832 15.916 15.916 0 1 1 0-31.832"
        ></path>
      </svg>
      <div class="flex flex-col justify-center items-center absolute w-full h-full text-dark">
        <span class="text-xs">Осталось</span>
        <span class="text-lg font-bold">{{ remainingRecognitions }}</span>

      </div>
    </div>
    <svg @click="goToSettings" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white cursor-pointer absolute bottom-4 right-4">
      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm.75-11.25a.75.75 0 0 0-1.5 0v2.5h-2.5a.75.75 0 0 0 0 1.5h2.5v2.5a.75.75 0 0 0 1.5 0v-2.5h2.5a.75.75 0 0 0 0-1.5h-2.5v-2.5Z" clip-rule="evenodd" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "BurnedWidget",
  props: {
    telegramId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      remainingRecognitions: 0
    };
  },
  methods: {
    goToSettings() {
      this.$router.push({name: 'settings'});
    },
    async fetchRemainingRecognitions() {
      try {
        const response = await fetch(`/api/recognitions/${this.telegramId}`, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        const data = await response.json();
        this.remainingRecognitions = data.remainingRecognitions;
      } catch (error) {
        console.error('Error fetching remaining recognitions:', error);
      }
    }
  },
  mounted() {
    this.fetchRemainingRecognitions();
  }
};
</script>

<style scoped>
.bg-dark-green {
  background-color: #F8C100;
}

.text-dark {
  color: #333333;
}
</style>
