<template>
  <div class="flex flex-col space-y-4 relative">
    <!-- Вес -->
    <div class="bg-light-green p-4 rounded-lg shadow-md flex justify-between items-center relative">
      <div>
        <h3 class="text-white text-lg font-bold text-left">Ваш вес</h3>
        <p class="text-white text-2xl font-bold text-left">{{ weight }} кг</p>
      </div>
      <svg @click="showWeightModal = true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="absolute top-2 right-2 w-6 h-6 text-white cursor-pointer">
        <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm.75-11.25a.75.75 0 0 0-1.5 0v2.5h-2.5a.75.75 0 0 0 0 1.5h2.5v2.5a.75.75 0 0 0 1.5 0v-2.5h2.5a.75.75 0 0 0 0-1.5h-2.5v-2.5Z" clip-rule="evenodd" />
      </svg>
    </div>

    <!-- Модальное окно для изменения веса -->
    <div v-if="showWeightModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-white p-4 rounded-lg">
        <h3 class="text-lg font-bold mb-2">Изменить вес</h3>
        <input v-model="newWeight" type="number" class="border p-2 rounded mb-4 w-full" />
        <div class="flex justify-end space-x-2">
          <button @click="showWeightModal = false" class="bg-red-500 text-white px-4 py-2 rounded">Отмена</button>
          <button @click="updateWeight" class="bg-green-500 text-white px-4 py-2 rounded">Сохранить</button>
        </div>
      </div>
    </div>

    <!-- Цель -->
    <div class="bg-dark-green p-4 rounded-lg shadow-md flex justify-between items-center relative">
      <div>
        <h3 class="text-white text-lg font-bold text-left">Ваша цель</h3>
        <p class="text-white text-xl font-bold text-left">{{ goal }}</p>
      </div>
      <svg @click="showGoalModal = true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="absolute top-2 right-2 w-6 h-6 text-white cursor-pointer">
        <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm.75-11.25a.75.75 0 0 0-1.5 0v2.5h-2.5a.75.75 0 0 0 0 1.5h2.5v2.5a.75.75 0 0 0 1.5 0v-2.5h2.5a.75.75 0 0 0 0-1.5h-2.5v-2.5Z" clip-rule="evenodd" />
      </svg>
    </div>

    <!-- Модальное окно для изменения цели -->
    <div v-if="showGoalModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-white p-4 rounded-lg">
        <h3 class="text-lg font-bold mb-2">Изменить цель</h3>
        <select v-model="newGoal" class="border p-2 rounded mb-4 w-full">
          <option value="Похудение">Похудение</option>
          <option value="ЗОЖ">ЗОЖ</option>
          <option value="Другое">Другое</option>
        </select>
        <div class="flex justify-end space-x-2">
          <button @click="showGoalModal = false" class="bg-red-500 text-white px-4 py-2 rounded">Отмена</button>
          <button @click="updateGoal" class="bg-green-500 text-white px-4 py-2 rounded">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WeightGoalWidget",
  props: {
    telegramId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      weight: 70,
      goal: 'Похудение',
      showWeightModal: false,
      showGoalModal: false,
      newWeight: 70,
      newGoal: 'Похудение',
    };
  },
  methods: {
    async fetchWeightAndGoal() {
      try {
        const response = await fetch(`/api/user/${this.telegramId}`, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        const data = await response.json();
        this.weight = data.weight || this.weight;
        this.newWeight = data.weight || this.weight;
        this.goal = data.goal || this.goal;
        this.newGoal = data.goal || this.goal;
      } catch (error) {
        console.error('Error fetching weight and goal:', error);
      }
    },
    async updateWeight() {
      try {
        await fetch(`/api/user/${this.telegramId}/weight`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          },
          body: JSON.stringify({ weight: this.newWeight }),
        });
        this.weight = this.newWeight;
        this.showWeightModal = false;
      } catch (error) {
        console.error('Error updating weight:', error);
      }
    },
    async updateGoal() {
      try {
        await fetch(`/api/user/${this.telegramId}/goal`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          },
          body: JSON.stringify({ goal: this.newGoal }),
        });
        this.goal = this.newGoal;
        this.showGoalModal = false;
      } catch (error) {
        console.error('Error updating goal:', error);
      }
    },
  },
  async created() {
    await this.fetchWeightAndGoal();
  },
};
</script>

<style scoped>
.bg-light-green {
  background-color: #FF6F1E;
}

.bg-dark-green {
  background-color: #2C8065;
}
</style>
