<template>
  <div class="success-page flex flex-col items-center justify-center min-h-screen bg-green-100">
    <h1 class="text-4xl font-bold text-green-700 mb-4">Поздравляем!</h1>
    <p class="text-lg mb-6">Ваш тариф был успешно обновлён.</p>
    <button
        @click="goHome"
        class="bg-green-600 text-white py-3 px-6 rounded-lg hover:bg-green-700 focus:outline-none focus:ring focus:ring-green-300"
    >
      Вернуться на главную страницу
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push({name: 'home'}); // Переход на главную страницу
    }
  },
};
</script>

<style scoped>
.success-page {
  text-align: center;
  margin-top: 50px;
}

.success-page h1 {
  color: #28a745;
}

.success-page p {
  font-size: 18px;
}
</style>
