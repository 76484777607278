import { createRouter, createWebHistory } from 'vue-router';
import Home from '../assets/components/HomePage.vue';
import History from '../assets/components/HistoryPage.vue';
import Statistics from '../assets/components/StatisticsPage.vue';
import Settings from '../assets/components/SettingsPage.vue';
import SuccessPage from '../assets/components/SuccessPage.vue';
import FailPage from '../assets/components/FailPage.vue';
import ProfileForm from '../assets/components/ProfileForm.vue'; // Импортируем компонент формы профиля

const routes = [
    { path: '/', name: 'home', component: Home, props: true },
    { path: '/history', name: 'history', component: History, props: true },
    { path: '/statistics', name: 'statistics', component: Statistics, props: true },
    { path: '/settings', name: 'settings', component: Settings, props: true },
    { path: '/payment-success', name: 'payment-success', component: SuccessPage },
    { path: '/payment-fail', name: 'payment-fail', component: FailPage },
    { path: '/profile-form/:telegramId', name: 'profile-form', component: ProfileForm, props: true }, // Новый маршрут
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Обработка параметров и автоматическое перенаправление
router.beforeEach((to, from, next) => {
    const { startapp } = to.query;

    if (startapp === 'payment-success') {
        // Перенаправление на страницу успешного платежа
        return next({ name: 'payment-success' });
    } else if (startapp === 'payment-fail') {
        // Перенаправление на страницу неудачного платежа
        return next({ name: 'payment-fail' });
    }
    next();
});

export default router;
