<template>
  <div class="bg-white rounded-lg shadow p-4 md:p-6 mt-6 relative">
    <div class="flex justify-between items-center mb-3">
      <h5 class="text-2xl font-bold leading-none text-gray-900">Статистика</h5>
      <div class="relative">
        <button @click="toggleDropdown" class="border border-green-500 text-gray-700 p-2 rounded inline-flex items-center">
          {{ selectedMetricName }}
          <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
        <div v-if="dropdownOpen" class="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10 border border-gray-300">
          <ul>
            <li v-for="metric in metrics" :key="metric.value" class="p-2 hover:bg-gray-100 cursor-pointer" @click="selectMetric(metric.value)">
              {{ metric.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="loading" class="flex justify-center items-center h-32">
      <svg class="animate-spin h-10 w-10 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
    <div v-else class="flex justify-around">
      <div v-for="(data, index) in displayData" :key="index" class="flex flex-col items-center">
        <span class="text-sm font-normal mb-1">{{ data }}</span>
        <div class="w-2 bg-gray-200 rounded-full h-32 relative overflow-hidden">
          <div class="absolute bottom-0 w-full bg-red-500 rounded-full transition-all duration-500" :style="{ height: `${(data / maxValues[selectedMetric]) * 100}%` }"></div>
        </div>
        <span class="mt-1 text-sm">{{ daysOfWeek[index] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    telegramId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dropdownOpen: false,
      selectedMetric: 'calories',
      metrics: [
        { name: 'Калории', value: 'calories' },
        { name: 'Белки', value: 'protein' },
        { name: 'Углеводы', value: 'carbs' },
        { name: 'Жиры', value: 'fat' }
      ],
      chartData: {
        calories: Array(7).fill(0),
        protein: Array(7).fill(0),
        carbs: Array(7).fill(0),
        fat: Array(7).fill(0)
      },
      maxValues: {
        calories: 2500,
        protein: 200,
        carbs: 300,
        fat: 100
      },
      displayData: Array(7).fill(0),
      daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      loading: true
    };
  },
  computed: {
    selectedMetricName() {
      return this.metrics.find(metric => metric.value === this.selectedMetric).name;
    }
  },
  watch: {
    selectedMetric(newValue) {
      this.displayData = this.chartData[newValue];
    }
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectMetric(metric) {
      this.selectedMetric = metric;
      this.dropdownOpen = false;
    },
    async fetchWeeklyStats() {
      try {
        const response = await axios.get(`/api/weekly_stats/${this.telegramId}`, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        const data = response.data;
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 6);

        data.forEach(item => {
          const dayIndex = (new Date(item.day).getDay() + 6) % 7; // Приведение дня недели к индексу
          this.chartData.calories[dayIndex] = parseFloat(item.total_calories) || 0;
          this.chartData.protein[dayIndex] = parseFloat(item.total_protein) || 0;
          this.chartData.carbs[dayIndex] = parseFloat(item.total_carbohydrates) || 0;
          this.chartData.fat[dayIndex] = parseFloat(item.total_fat) || 0;
        });

        this.displayData = this.chartData[this.selectedMetric];
      } catch (error) {
        console.error('Error fetching weekly stats:', error);
      } finally {
        this.loading = false;
      }
    }
  },
  async created() {
    await this.fetchWeeklyStats();
  }
};
</script>

<style scoped>
.chart-container {
  margin-top: 10px;
}
.overflow-hidden {
  overflow: hidden;
}
.transition-all {
  transition: all 0.5s ease;
}
</style>
