<template>
  <div class="space-y-4">
    <!-- Новый блок с приветственным сообщением -->
    <div class="flex items-center bg-red-500 text-white rounded-lg p-4 relative">
      <div class="flex-1">
        <h2 class="text-xl text-left font-bold">Давайте посмотрим, что <br> вы скушали сегодня!</h2>
      </div>
      <img :src="chefImage" alt="Chef" class="absolute bottom-0 right-0 h-28 w-auto"/>
    </div>

    <!-- Блок для отсканированных блюд -->
    <h2 class="text-xl font-bold mt-6">Отсканированная еда</h2>
    <div v-if="scannedDishes.length">
      <DishCardfree
          v-for="dish in scannedDishes"
          :key="dish.id"
          :dish="dish"
          @delete-dish="deleteScannedDish"
      />
    </div>
    <div v-else>
      <p>Нет отсканированных блюд.</p>
    </div>

    <!-- Блок для блюд сегодня -->
    <h2 class="text-xl font-bold mt-6">Блюда сегодня</h2>
    <div v-if="dishes.length">
      <DishCard
          v-for="dish in dishes"
          :key="dish.id"
          :dish="dish"
          @delete-dish="deleteDish"
      />
    </div>
    <div v-else>
      <p>Нет блюд на сегодня.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import chefImage from '@/assets/chef.png';
import DishCard from '@/assets/components/DishCard.vue';
import DishCardfree from '@/assets/components/DishCardfree.vue';

export default {
  name: 'HistoryPage',
  components: {
    DishCard,
    DishCardfree
  },
  data() {
    return {
      chefImage,
      scannedDishes: [],
      dishes: [],
      user: null,
      loading: true,
      isTelegram: false,
    };
  },
  methods: {
    checkIfTelegram() {
      if (window.Telegram && window.Telegram.WebApp) {
        this.isTelegram = true;
        this.fetchUserData();
      } else {
        this.isTelegram = false;
      }
      console.log("isTelegram:", this.isTelegram);
    },
    async loadTelegramWebAppScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-web-app.js";
        script.onload = () => {
          console.log("Telegram WebApp script loaded");
          this.checkIfTelegram();
          resolve();
        };
        script.onerror = () => {
          console.error("Error loading Telegram WebApp script");
          this.isTelegram = false;
          reject();
        };
        document.head.appendChild(script);
      });
    },
    async fetchUserData() {
      const initData = window.Telegram.WebApp.initDataUnsafe || null;
      console.log("Telegram WebApp initData:", initData);
      if (initData && initData.user) {
        this.user = initData.user;
        console.log("User data:", this.user);
        await this.fetchDishesForToday(); // Загружаем блюда после получения данных пользователя
        await this.fetchScannedDishes(); // Загружаем отсканированные блюда
      }
    },
    async fetchDishesForToday() {
      if (!this.user || !this.user.id) {  // Убедитесь, что user.id определен
        console.log("User not available or telegram ID not available");
        return;
      }
      try {
        console.log('Fetching today\'s dishes...');
        const timestamp = new Date().getTime();
        const response = await axios.get(`/api/dishes/details/today/${this.user.id}?_=${timestamp}`, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        console.log('Dishes for Today Data:', response.data);
        this.dishes = response.data.map(dish => ({
          id: dish.id,
          name: dish.name,
          description: dish.description,
          calories: dish.calories,
          protein: dish.protein,
          fat: dish.fat,
          carbohydrates: dish.carbohydrates,
          sugars: dish.sugars,
          fiber: dish.fiber,
          totalWeight: dish.total_weight,
          timeAgo: this.calculateTimeAgo(dish.consumption_time),
          image: dish.photo_url || '',
        }));
        console.log('Processed Today\'s Dishes:', this.dishes);
      } catch (error) {
        console.error('Error fetching today\'s dishes:', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchScannedDishes() {
      if (!this.user || !this.user.id) {  // Убедитесь, что user.id определен
        console.log("User not available or telegram ID not available");
        return;
      }
      try {
        console.log('Fetching scanned dishes...');
        const timestamp = new Date().getTime();
        const response = await axios.get(`/api/temp_dishes/${this.user.id}?_=${timestamp}`, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        console.log('Scanned Dishes Data:', response.data);
        this.scannedDishes = response.data.map(dish => ({
          id: dish.id,
          name: dish.name,
          description: dish.description,
          calories: dish.calories,
          protein: dish.protein,
          fat: dish.fat,
          carbohydrates: dish.carbohydrates,
          sugars: dish.sugars,
          fiber: dish.fiber,
          totalWeight: dish.total_weight,
          image: dish.photo_url || '',
        }));
        console.log('Processed Scanned Dishes:', this.scannedDishes);
      } catch (error) {
        console.error('Error fetching scanned dishes:', error);
      } finally {
        this.loading = false;
      }
    },
    calculateTimeAgo(timestamp) {
      const diff = Math.floor((new Date() - new Date(timestamp)) / 60000); // Разница в минутах
      if (diff < 60) return `${diff} м`;
      const hours = Math.floor(diff / 60);
      return `${hours} ч`;
    },
    async deleteDish(id) {
      if (confirm("Вы действительно хотите удалить это блюдо?")) {
        try {
          await axios.delete(`/api/dishes/${id}`, {
            headers: {
              'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
            }
          });
          this.dishes = this.dishes.filter(dish => dish.id !== id);
          console.log('Dish deleted:', id);
        } catch (error) {
          console.error('Error deleting dish:', error);
        }
      }
    },
    async deleteScannedDish(id) {
      this.scannedDishes = this.scannedDishes.filter(dish => dish.id !== id);
      try {
        await axios.delete(`/api/temp_dishes/${id}`, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        console.log('Scanned dish deleted:', id);
      } catch (error) {
        console.error('Error deleting scanned dish:', error);
      }
    }
  },
  async mounted() {
    if (window.Telegram && window.Telegram.WebApp) {
      this.checkIfTelegram();
    } else {
      await this.loadTelegramWebAppScript();
    }
  }
};
</script>

<style scoped>
.bg-red-500 {
  background-color: #F56565;
}

.text-white {
  color: #FFFFFF;
}

.space-y-4 > * + * {
  margin-top: 1rem;
}

.text-gray-500 {
  color: #A0AEC0;
}

.mt-6 {
  margin-top: 1.5rem;
}
</style>
