<template>
  <div id="app" class="font-sans text-center text-gray-800 bg-custom-bg min-h-screen">
    <div v-if="loading" class="flex justify-center items-center h-screen">
      <Loader />
    </div>
    <div v-else>
      <div v-if="user && user.id">
        <header class="bg-gray-100 p-4 flex justify-between items-center">
          <h1 class="text-3xl font-bold">Ваш дневник:</h1>
          <div class="flex items-center">
            <p class="text-lg mr-4">{{ user.name }}</p>
            <div class="profile-icon">
              <img v-if="user.profile_picture_url" :src="user.profile_picture_url" alt="Profile Picture" class="h-10 w-10 rounded-full object-cover border border-gray-300">
              <UserIcon v-else class="h-6 w-6 text-gray-800" />
            </div>
          </div>
        </header>
        <main class="p-4 pb-20">
          <router-view :user="user" @loading="setLoading" />
        </main>
        <footer class="bg-custom-green text-white p-4 flex justify-around fixed bottom-0 w-full">
          <button @click="goTo('home')" :class="{ 'text-gray-800': currentPath === 'home' }" class="flex flex-col items-center">
            <HomeIcon class="w-6 h-6" />
          </button>
          <button @click="goTo('history')" :class="{ 'text-gray-800': currentPath === 'history' }" class="flex flex-col items-center">
            <PencilSquareIcon class="w-6 h-6" />
          </button>
          <button @click="goTo('statistics')" :class="{ 'text-gray-800': currentPath === 'statistics' }" class="flex flex-col items-center">
            <ChartBarIcon class="w-6 h-6" />
          </button>
          <button @click="goTo('settings')" :class="{ 'text-gray-800': currentPath === 'settings' }" class="flex flex-col items-center">
            <Cog6ToothIcon class="w-6 h-6" />
          </button>
        </footer>
      </div>
      <div v-else>
        <p>Этот бот работает только в Telegram. Пожалуйста, откройте его в Telegram.</p>
        <button @click="openTelegram" class="px-4 py-2 bg-blue-500 text-white rounded">Открыть в Telegram</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { HomeIcon, PencilSquareIcon, ChartBarIcon, Cog6ToothIcon, UserIcon } from '@heroicons/vue/24/solid';
import Loader from '@/assets/components/Loader.vue';

export default {
  data() {
    return {
      user: null,
      isTelegram: false,
      currentPath: 'home',
      loading: true,
    };
  },
  components: {
    HomeIcon,
    PencilSquareIcon,
    ChartBarIcon,
    Cog6ToothIcon,
    UserIcon,
    Loader,
  },
  methods: {
    async sendLog(message) {
      try {
        await axios.post('/api/log', {message}, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
      } catch (error) {
        console.error('Error sending log:', error);
      }
    },
    checkIfTelegram() {
      if (window.Telegram && window.Telegram.WebApp) {
        this.isTelegram = true;
        this.loadUserData();
      } else {
        this.isTelegram = false;
      }
      this.sendLog(`isTelegram: ${this.isTelegram}`);
    },
    async loadTelegramWebAppScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-web-app.js";
        script.onload = () => {
          this.sendLog("Telegram WebApp script loaded");
          this.checkIfTelegram();
          resolve();
        };
        script.onerror = () => {
          this.sendLog("Error loading Telegram WebApp script");
          this.isTelegram = false;
          reject();
        };
        document.head.appendChild(script);
      });
    },
    async loadUserData() {
      const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;

      if (userId) {
        try {
          const apiKey = process.env.VUE_APP_API_KEY;  // Убедитесь, что API ключ загружается
          if (!apiKey) {
            this.sendLog("API ключ не найден");
            return;
          }

          const response = await axios.get(`/api/user/${userId}`, {
            headers: {
              Authorization: `Bearer ${apiKey}`
            }
          });
          this.user = response.data;
          this.sendLog(`User data loaded: ${JSON.stringify(this.user)}`);
          this.checkInitialRoute();
        } catch (error) {
          this.sendLog(`Error loading user data: ${error.message}`);
          console.error("Error loading user data:", error);
        } finally {
          this.loading = false;
        }
      } else {
        this.sendLog("No user ID found in Telegram WebApp data");
        console.log("No user ID found in Telegram WebApp data");
        this.loading = false;
      }
    },
    goTo(page) {
      this.sendLog(`Navigating to ${page}`);
      console.log(`Navigating to ${page}`);
      this.currentPath = page;
      this.$router.push({name: page});
    },
    openTelegram() {
      window.location.href = "https://t.me/AvacaloriesBot";
    },
    checkInitialRoute() {
      const startAppParam = window.Telegram.WebApp.initDataUnsafe?.start_param;
      this.sendLog(`Initial route from initData: ${startAppParam}`);
      if (startAppParam && startAppParam !== this.currentPath) {
        this.goTo(startAppParam);
      } else {
        this.goTo('home');
      }
    },
    setLoading(value) {
      this.loading = value;
    }
  },
  async mounted() {
    this.currentPath = this.$route.name;
    console.log(`Initial currentPath: ${this.currentPath}`);
    if (window.Telegram && window.Telegram.WebApp) {
      this.checkIfTelegram();
    } else {
      this.loadTelegramWebAppScript();
    }
  },
  watch: {
    $route(to) {
      console.log(`Route changed to: ${to.name}`);
      this.currentPath = to.name;
    }
  }
};
</script>

<style>
.bg-custom-green {
  background-color: #10B981; /* зеленый цвет */
}

.text-gray-500 {
  color: #6b7280; /* Светло-серый цвет для активной иконки */
}

.profile-icon {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1d5db; /* Тонкая серая граница */
  border-radius: 50%;
}
</style>
