<template>
  <div class="flex items-center justify-between bg-white rounded-lg shadow-md p-4 mb-4">
    <div class="flex items-center w-full">
      <img :src="dish.image" alt="Dish Image" class="w-16 h-16 rounded-lg mr-4 object-cover">
      <div class="text-left flex-1">
        <h3 class="text-lg font-bold text-left">{{ dish.name }}</h3>
        <p class="text-sm text-gray-600 text-left">{{ dish.calories }} Ккал</p>
        <p class="text-sm text-gray-600 text-left">{{ dish.totalWeight }} гр.</p>
      </div>
    </div>
    <div class="flex flex-col items-end">
      <p class="text-sm text-gray-600 mb-2">{{ dish.timeAgo }}</p>
      <div class="flex items-center space-x-2">
        <button @click="deleteDish(dish.id)" class="text-red-500 hover:text-red-700">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
            <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 10.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5Zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clip-rule="evenodd"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DishCard',
  props: {
    dish: {
      type: Object,
      required: true
    }
  },
  methods: {
    async deleteDish(id) {
      try {
        const response = await fetch(`/api/dishes/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_API_KEY}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Ошибка при удалении блюда');
        }

        // Emit event to parent component to update the list
        this.$emit('delete-dish', id);
        console.log(`Dish with id ${id} deleted successfully`);
      } catch (error) {
        console.error('Error deleting dish:', error);
      }
    }
  }
};
</script>

<style scoped>
img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 8px;
}
</style>
