<template>
  <div class="fail-page flex flex-col items-center justify-center min-h-screen bg-red-100">
    <h1 class="text-4xl font-bold text-red-700 mb-4">Ошибка оплаты</h1>
    <p class="text-lg mb-6">К сожалению, произошла ошибка при обработке вашего платежа.</p>
    <button
        @click="goHome"
        class="bg-red-600 text-white py-3 px-6 rounded-lg hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-300"
    >
      Вернуться на главную страницу
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push({ name: 'home' }); // Переход на главную страницу
    }
  },
};
</script>

<style scoped>
.fail-page {
  text-align: center;
  margin-top: 50px;
}

.fail-page h1 {
  color: #dc3545;
}

.fail-page p {
  font-size: 18px;
}
</style>
