<template>
  <div class="max-w-2xl mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">Заполнить профиль</h1>
    <form @submit.prevent="submitForm">
      <div class="mb-4">
        <label for="name" class="block text-gray-700">Имя</label>
        <input type="text" id="name" v-model="form.name" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm">
      </div>
      <div class="mb-4">
        <label for="age" class="block text-gray-700">Возраст</label>
        <input type="number" id="age" v-model="form.age" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm">
      </div>
      <div class="mb-4">
        <label for="weight" class="block text-gray-700">Вес (кг)</label>
        <input type="number" id="weight" v-model="form.weight" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm">
      </div>
      <div class="mb-4">
        <label for="height" class="block text-gray-700">Рост (см)</label>
        <input type="number" id="height" v-model="form.height" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm">
      </div>
      <div class="mb-4">
        <label for="gender" class="block text-gray-700">Пол</label>
        <select id="gender" v-model="form.gender" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm">
          <option value="М">Мужской</option>
          <option value="Ж">Женский</option>
          <option value="Другое">Другое</option>
        </select>
      </div>
      <div class="mb-4">
        <label for="goal" class="block text-gray-700">Цель</label>
        <select id="goal" v-model="form.goal" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm">
          <option value="Похудение">Похудение</option>
          <option value="ЗОЖ">ЗОЖ</option>
          <option value="Другое">Другое</option>
        </select>
      </div>
      <div class="mb-4">
        <label for="calorie_intake" class="block text-gray-700">Необходимое количество калорий в день</label>
        <input type="number" id="calorie_intake" v-model="form.calorie_intake" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm">
      </div>
      <div class="mb-4">
        <label for="timezone" class="block text-gray-700">Часовой пояс</label>
        <select id="timezone" v-model="form.timezone" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm">
          <option value="Europe/Moscow">Europe/Moscow</option>
          <option value="Asia/Yekaterinburg">Asia/Yekaterinburg</option>
          <option value="Europe/Kaliningrad">Europe/Kaliningrad</option>
          <option value="Asia/Novosibirsk">Asia/Novosibirsk</option>
          <option value="Asia/Krasnoyarsk">Asia/Krasnoyarsk</option>
          <option value="Asia/Irkutsk">Asia/Irkutsk</option>
          <option value="Asia/Yakutsk">Asia/Yakutsk</option>
          <option value="Asia/Vladivostok">Asia/Vladivostok</option>
          <option value="Asia/Magadan">Asia/Magadan</option>
          <option value="Asia/Kamchatka">Asia/Kamchatka</option>
        </select>
      </div>
      <div class="mb-4">
        <label for="notifications_enabled" class="block text-gray-700">Уведомления</label>
        <input type="checkbox" id="notifications_enabled" v-model="form.notifications_enabled" class="mt-1 block">
      </div>
      <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded shadow-lg">Сохранить</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProfileForm',

  data() {
    return {
      form: {
        name: '',
        age: null,
        weight: null,
        height: null,
        gender: 'М',
        goal: 'Похудение',
        calorie_intake: null,
        timezone: 'Europe/Moscow',
        notifications_enabled: false,
      }
    };
  },
  async created() {
    try {
      const response = await axios.get(`/api/user-profile/${this.$route.params.telegramId}`, {
        headers: {
          'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
        }
      });
      this.form = { ...this.form, ...response.data };
    } catch (error) {
      console.error('Ошибка при загрузке данных профиля:', error);
    }
  },
  methods: {
    async submitForm() {
      try {
        await axios.put(`/api/user-profile/${this.$route.params.telegramId}`, this.form, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        alert('Профиль успешно обновлен!');
        this.$router.push('/settings');
      } catch (error) {
        console.error('Ошибка при сохранении данных профиля:', error);
        alert('Не удалось сохранить профиль. Попробуйте позже.');
      }
    }
  }
};
</script>

<style scoped>
/* Стиль для формы можно настроить по необходимости */
</style>
