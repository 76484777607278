<template>
  <div class="max-w-4xl mx-auto p-4">
    <!-- Карточка пользователя -->
    <div class="bg-white shadow-lg rounded-lg p-6 mb-6">
      <div class="flex items-center space-x-4">
        <img v-if="user.profile_picture_url" :src="user.profile_picture_url" alt="Profile Picture" class="w-16 h-16 rounded-full object-cover">
        <img v-else src="default-profile.png" alt="Default Profile Picture" class="w-16 h-16 rounded-full object-cover">
        <div>
          <h2 class="text-2xl font-bold text-gray-800">{{ user.name ? user.name : 'Имя не указано' }}</h2>
        </div>
      </div>
      <div class="mt-4 grid grid-cols-2 gap-4">
        <div class="text-gray-600">
          <p v-if="user.weight"><strong>Вес:</strong> {{ user.weight }} кг</p>
          <p v-if="user.height"><strong>Рост:</strong> {{ user.height }} см</p>
          <p v-if="user.age"><strong>Возраст:</strong> {{ user.age }} лет</p>
        </div>
        <div class="text-gray-600">
          <p v-if="user.goal"><strong>Цель:</strong> {{ user.goal }}</p>
          <p v-if="user.gender"><strong>Пол:</strong> {{ user.gender }}</p>
          <p v-if="user.phone"><strong>Телефон:</strong> {{ user.phone }}</p>
        </div>
      </div>
      <div class="mt-4">
        <p v-if="user.calorie_intake"><strong>Калории в день:</strong> {{ user.calorie_intake }} ккал</p>
        <p><strong>Осталось распознаваний:</strong> {{ user.free_tries_left }}</p>
      </div>

      <!-- Кнопка для заполнения профиля -->
      <button @click="navigateToProfileForm" class="bg-blue-500 text-white px-4 py-2 rounded shadow-lg w-full mt-4">
        Заполнить профиль
      </button>
    </div>

    <!-- Кнопка для отображения тарифов -->
    <button @click="togglePlansVisibility" class="bg-green-500 text-white px-4 py-2 rounded shadow-lg w-full mb-4">
      {{ showPlans ? 'Скрыть тарифы' : 'Показать тарифы' }}
    </button>

    <!-- Карточки тарифных планов -->
    <div v-if="showPlans" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      <div v-for="plan in plans" :key="plan.id" class="bg-white shadow-lg rounded-lg p-6">
        <h3 class="text-xl font-bold text-gray-800 mb-2">{{ plan.name }}</h3>
        <p v-if="plan.description" class="text-gray-600 mb-4">{{ plan.description }}</p>
        <p v-if="plan.original_price" class="text-gray-400 line-through mb-1">Было: {{ plan.original_price }} руб.</p>
        <p class="text-gray-600 mb-4">Цена: {{ plan.price }} руб.</p>
        <p class="text-gray-600 mb-4">Распознаваний: {{ plan.recognitions }}</p>
        <button @click="initiatePayment(plan.id)" class="bg-green-500 text-white px-4 py-2 rounded shadow-lg w-full" :disabled="loadingPayment">
          {{ loadingPayment ? 'Загрузка...' : 'Оплатить' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SettingsPage',

  data() {
    return {
      user: null,
      plans: [],
      showPlans: false,
      loadingPayment: false,
      isTelegram: false,
    };
  },
  methods: {
    checkIfTelegram() {
      if (window.Telegram && window.Telegram.WebApp) {
        this.isTelegram = true;
        this.fetchUserData();
      } else {
        this.isTelegram = false;
      }
    },
    async loadTelegramWebAppScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-web-app.js";
        script.onload = () => {
          this.checkIfTelegram();
          resolve();
        };
        script.onerror = () => {
          this.isTelegram = false;
          reject();
        };
        document.head.appendChild(script);
      });
    },
    async fetchUserData() {
      const initData = window.Telegram.WebApp.initDataUnsafe || null;
      if (initData && initData.user) {
        const telegramId = initData.user.id;
        try {
          const response = await axios.get(`/api/user-profile/${telegramId}`, {
            headers: {
              'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
            }
          });
          this.user = response.data;
        } catch (error) {
          console.error('Ошибка при получении данных пользователя:', error);
          alert('Не удалось загрузить данные профиля. Попробуйте позже.');
        }
      }
    },
    async fetchPlans() {
      try {
        const response = await axios.get('/api/plans', {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        this.plans = response.data;
      } catch (error) {
        console.error('Ошибка при загрузке тарифов:', error);
        alert('Ошибка при загрузке тарифов. Попробуйте позже.');
      }
    },
    togglePlansVisibility() {
      this.showPlans = !this.showPlans;
    },
    async initiatePayment(planId) {
      this.loadingPayment = true;
      try {
        const response = await axios.post('/api/initiate-payment', {
          telegramId: this.user.telegram_id,
          planId: planId
        }, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        if (response.data.PaymentURL) {
          window.location.href = response.data.PaymentURL;
        } else {
          console.error('Payment URL not found in response:', response.data);
          alert('Ошибка инициализации платежа. Пожалуйста, попробуйте снова.');
        }
      } catch (error) {
        console.error('Error initiating payment:', error);
        alert('Ошибка инициализации платежа. Пожалуйста, попробуйте позже.');
      } finally {
        this.loadingPayment = false;
      }
    },
    navigateToProfileForm() {
      this.$router.push(`/profile-form/${this.user.telegram_id}`);
    }
  },
  async mounted() {
    if (window.Telegram && window.Telegram.WebApp) {
      this.checkIfTelegram();
    } else {
      await this.loadTelegramWebAppScript();
    }
    await this.fetchPlans();  // Загружаем тарифы после инициализации
  }
};
</script>

<style scoped>
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>
