<template>
  <div>
    <div v-if="loading" class="flex justify-center items-center h-screen">
      <Loader />
    </div>
    <div v-else>
      <nutrition-widget :telegramId="telegramId" :apiKey="apiKey" class="mb-6"></nutrition-widget>
      <div class="flex justify-between mb-6">
        <weight-and-goal-widget class="w-1/2 mr-2" :telegramId="telegramId" :apiKey="apiKey"></weight-and-goal-widget>
        <burned-widget class="w-1/2 ml-2" :telegramId="telegramId" :apiKey="apiKey"></burned-widget>
      </div>
      <statistics-widget :telegramId="telegramId" :apiKey="apiKey"></statistics-widget>
    </div>
  </div>
</template>

<script>
import NutritionWidget from '@/assets/components/NutritionWidget.vue';
import StatisticsWidget from '@/assets/components/StatisticsWidget.vue';
import Loader from '@/assets/components/Loader.vue';
import WeightAndGoalWidget from '@/assets/components/WeightAndGoalWidget.vue';
import BurnedWidget from '@/assets/components/BurnedWidget.vue';

export default {
  name: "HomePage",
  components: {
    NutritionWidget,
    StatisticsWidget,
    Loader,
    WeightAndGoalWidget,
    BurnedWidget
  },
  data() {
    return {
      telegramId: null,
      loading: true,
      apiKey: process.env.VUE_APP_API_KEY, // Загружаем API ключ из env
    };
  },
  async created() {
    const initData = window.Telegram.WebApp.initDataUnsafe || null;
    if (initData && initData.user) {
      this.telegramId = initData.user.id;
      if (this.telegramId) {
        this.loading = false;
      } else {
        console.error('telegramId is undefined');
      }
    } else {
      console.error('initData or user is undefined');
    }
  }
};
</script>

<style scoped>
/* Пример отступов */
.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}
</style>
