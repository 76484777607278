<template>
  <div class="max-w-sm w-full bg-custom-green rounded-lg shadow p-4 md:p-6 flex flex-col">
    <div v-if="loading" class="flex justify-center items-center h-full w-full">
      <Loader />
    </div>
    <div v-else>
      <div class="flex justify-between items-center mb-4 mt-2">
        <h5 class="text-2xl font-bold leading-none text-white">Сегодня</h5>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-white">
          <path fill-rule="evenodd" d="M13.5 4.938a7 7 0 1 1-9.006 1.737c.202-.257.59-.218.793.039.278.352.594.672.943.954.332.269.786-.049.773-.476a5.977 5.977 0 0 1 .572-2.759 6.026 6.026 0 0 1 2.486-2.665c.247-.14.55-.016.677.238A6.967 6.967 0 0 0 13.5 4.938ZM14 12a4 4 0 0 1-4 4c-1.913 0-3.52-1.398-3.91-3.182-.093-.429.44-.643.814-.413a4.043 4.043 0 0 0 1.601.564c.303.038.531-.24.51-.544a5.975 5.975 0 0 1 1.315-4.192.447.447 0 0 1 .431-.16A4.001 4.001 0 0 1 14 12Z" clip-rule="evenodd" />
        </svg>
      </div>
      <div class="flex items-center">
        <div class="relative w-28 h-28 mr-4">
          <svg class="absolute w-full h-full text-gray-100 opacity-50" viewBox="0 0 36 36">
            <path
                class="stroke-current"
                stroke-width="2"
                fill="none"
                d="M18 2.084a15.916 15.916 0 1 1 0 31.832 15.916 15.916 0 1 1 0-31.832"
            ></path>
          </svg>
          <svg class="absolute w-full h-full text-green-300" viewBox="0 0 36 36">
            <path
                class="stroke-current"
                :stroke-dasharray="`${Math.min((totalCalories / 2500) * 100, 100)}, 100`"
                stroke-width="4"
                fill="none"
                d="M18 2.084a15.916 15.916 0 1 1 0 31.832 15.916 15.916 0 1 1 0-31.832"
            ></path>
          </svg>
          <div class="flex flex-col justify-center items-center absolute w-full h-full text-white">
            <span class="text-xs">Калории</span>
            <span class="text-lg font-bold">{{ Math.floor(totalCalories) }}</span>
          </div>
        </div>
        <div class="flex flex-col space-y-2">
          <div class="flex items-center text-xs text-white">
            <span class="w-14 text-right">Белки</span>
            <div class="w-20 bg-gray-100 rounded-full h-1.5 mx-2">
              <div
                  :class="{'bg-red-500': totalProtein > 200, 'bg-custombar-purple': totalProtein <= 200}"
                  class="h-1.5 rounded-full"
                  :style="{ width: `${Math.min((totalProtein / 200) * 100, 100)}%` }"
              ></div>
            </div>
            <span>{{ Math.floor(totalProtein) }} г</span>
          </div>
          <div class="flex items-center text-xs text-white">
            <span class="w-14 text-right">Углеводы</span>
            <div class="w-20 bg-gray-100 rounded-full h-1.5 mx-2">
              <div
                  :class="{'bg-red-500': totalCarbohydrates > 300, 'bg-custombar-purple': totalCarbohydrates <= 300}"
                  class="h-1.5 rounded-full"
                  :style="{ width: `${Math.min((totalCarbohydrates / 300) * 100, 100)}%` }"
              ></div>
            </div>
            <span>{{ Math.floor(totalCarbohydrates) }} г</span>
          </div>
          <div class="flex items-center text-xs text-white">
            <span class="w-14 text-right">Жиры</span>
            <div class="w-20 bg-gray-100 rounded-full h-1.5 mx-2">
              <div
                  :class="{'bg-red-500': totalFat > 100, 'bg-custombar-purple': totalFat <= 100}"
                  class="h-1.5 rounded-full"
                  :style="{ width: `${Math.min((totalFat / 100) * 100, 100)}%` }"
              ></div>
            </div>
            <span>{{ Math.floor(totalFat) }} г</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from './Loader.vue';

export default {
  name: "NutritionWidget",
  props: {
    telegramId: {
      type: Number,
      required: true
    },
    apiKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      totalProtein: 0,
      totalFat: 0,
      totalCarbohydrates: 0,
      totalCalories: 0,
      loading: true,
    };
  },
  async created() {
    if (this.telegramId) {
      try {
        const response = await fetch(`/api/nutrition/${this.telegramId}`, {
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`
          }
        });
        if (!response.ok) {
          throw new Error('Ошибка при получении данных');
        }
        const data = await response.json();
        this.totalProtein = data.total_protein || 0;
        this.totalFat = data.total_fat || 0;
        this.totalCarbohydrates = data.total_carbohydrates || 0;
        this.totalCalories = data.total_calories || 0;
      } catch (error) {
        console.error('Error fetching nutrition data:', error);
      } finally {
        this.loading = false;
      }
    } else {
      console.error('telegramId is undefined');
      this.loading = false;
    }
  },
  components: {
    Loader
  }
};
</script>

<style scoped>
.text-red-500 {
  color: #f56565;
}
.bg-red-500 {
  background-color: #f56565;
}
.bg-custombar-purple {
  background-color: #FF9090;
}
</style>
